import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ServerService } from '../services/server.service';
// import { EditorModule } from "@tinymce/tinymce-angular";
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';

import Swal from 'sweetalert2'

declare var $:any;
declare var iziToast:any;
declare var tinymce:any;
export interface Collobrator {
  email_name: string;
}
export interface EmailAddress {
  email_to: string;
}
@Component({
  selector: 'app-create-internal-mail',
  templateUrl: './create-internal-mail.component.html',
  styleUrls: ['./create-internal-mail.component.css']
})
export class CreateInternalMailComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  showdept = false;
  showassign = false;
  showstatus = false;
  showpriority = false;
  presentEmails = false;
  presentCCEmails=false;
  showtoEmail = false;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  collobrators: Collobrator[] = [ ];
  EmailToAddress: EmailAddress[] = [ ];

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    const input = event.input;
    let myItems = this.collobrators.filter(item => item.email_name === value);
    // Add our fruit    
    if (myItems.length < 1) {
      let item2 = this.EmailToAddress.filter(item => item.email_to === value);
          if (item2.length < 1) {
            this.presentCCEmails = false;  
                if (value) {
                  this.collobrators.push({email_name: value}); 
                }
                if (input) {
                  input.value = '';
                }
          }else{
              this.presentCCEmails = true;      
          }
  } else {
    this.presentCCEmails = true;
  }
  }

  remove(collobrator: Collobrator): void {
    const index = this.collobrators.indexOf(collobrator);

    if (index >= 0) {
      this.collobrators.splice(index, 1);
      this.presentEmails = false;
    }
  }

//CC Collabrator End
//TO Address 

addTo(event: MatChipInputEvent): void {
  const value = (event.value || '').trim();
  const input = event.input;
    let myItems = this.EmailToAddress.filter(item => item.email_to === value);
    // Add our fruit
    if (myItems.length < 1) {
      let Items2 = this.collobrators.filter(item => item.email_name === value);
      if (Items2.length < 1) {
        this.presentEmails = false;
        if (value) {
          this.EmailToAddress.push({ email_to: value });
        }
        if (input) {
          input.value = '';
        }

      }
      else{
        this.presentEmails = true;
      }
    
    } else {
      this.presentEmails = true;
    }
    // Clear the input value
    // event.chipInput!.clear();
  }

removeTo(EmailAddress: EmailAddress): void {
  const index = this.EmailToAddress.indexOf(EmailAddress);

  if (index >= 0) {
    this.EmailToAddress.splice(index, 1);
  }
}
//TO Address  END



  admin_id;
  user_id;
  user_type_;
  user_type;
  agents_options;
  department_options;
  priority_options;
  status_options;
  richTextArea_id;
  email_from;
  constructor(private serverService: ServerService, private router:Router,private route: ActivatedRoute) { }
  userEmails = new FormGroup({
    primaryEmail: new FormControl('',[
      Validators.required
      // Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
    ])
    });  
  ngOnInit() {
    this.user_type_ = localStorage.getItem('user_type');
    this.email_from = localStorage.getItem('email_id');
    if(this.user_type_ == 'Employee')
      this.user_type =3;    
    else if(this.user_type_ == 'Admin')
       this.user_type =2;

    this.admin_id = localStorage.getItem('admin_id');
    this.user_id = localStorage.getItem('userId');
    this.richTextArea_id='richTextArea';

    this.initTiny();
    // this.initTiny();
    this.getAlldetailsOfAgents();
  }
  getAlldetailsOfAgents(){
    let access_token: any=localStorage.getItem('access_token');
    // var subject = $('#subject').val();
    // var description = btoa(tinymce.activeEditor.getContent());
    // console.log(tinymce.activeEditor.getContent());

    let api_req:any = '{"operation":"ticket", "moduleType":"ticket", "api_type":"web", "access_token":"'+access_token+'", "element_data":{"action":"getAlldetailsOfAgents","user_type":"'+this.user_type+'","user_id":"'+this.user_id+'","admin_id":"'+this.admin_id+'"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.status="true"){
      // this.agents_options=response.agents_options;
      this.department_options=response.department_options;
      this.priority_options=response.priority_options;
      this.status_options=response.status_options;
      }
      else{
        iziToast.warning({
          message: "Sorry not able to fetch Data.Please contact Admin",
          position: 'topRight'
        })
      }
    }, 
    (error)=>{
        console.log(error);
    });
  }


  initTiny() {
    var richTextArea_id = 'richTextArea';
   tinymce.init({
     selector: '.richTextArea',
     height: 500,
     plugins: 'advlist autolink formatpainter lists link  image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste  wordcount autolink lists media table',
     toolbar: 'undo redo |fullscreen|forecolor backcolor| formatselect | bold italic | \ undo redo | link image file| code | \
       alignleft aligncenter alignright alignjustify | \
       bullist numlist outdent indent | autoresize',
     paste_data_images: true,
     images_upload_url: 'upload.php',
     automatic_uploads: false,
     default_link_target:"_blank",
     extended_valid_elements : "a[href|target=_blank]",
     link_assume_external_targets: true,
    //  link_context_toolbar: true,
     images_upload_handler: function (blobInfo, success, failure) {
       var xhr, formData;

       xhr = new XMLHttpRequest();
       xhr.withCredentials = false;
       xhr.open('POST', 'upload.php');

       xhr.onload = function () {
         var json;

         if (xhr.status != 200) {
           failure('HTTP Error: ' + xhr.status);
           return;
         }

         json = JSON.parse(xhr.responseText);

         if (!json || typeof json.file_path != 'string') {
           failure('Invalid JSON: ' + xhr.responseText);
           return;
         }

         success(json.file_path);
       };

       formData = new FormData();
       formData.append('file', blobInfo.blob(), blobInfo.filename());

       xhr.send(formData);
     },
   });
   if (tinymce.editors.length > 0) {
     //tinymce.execCommand('mceFocus', true, richTextArea_id );       
     tinymce.execCommand('mceRemoveEditor',true, richTextArea_id);        
     tinymce.execCommand('mceAddEditor',true, richTextArea_id);
 }
 }


  // initTiny(){
    
  //   tinymce.init({
  //     selector : '.richTextArea',
  //     plugins : 'advlist autolink lists link  image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste help wordcount autolink lists media table',
  //     toolbar : 'undo redo| fullscreen | formatselect | fontselect | fontsizeselect| bold italic | \ undo redo | link image file| code | \
  //     alignleft aligncenter alignright alignjustify | \
  //     bullist numlist outdent indent | help',
  
  //     content_style: 'body {font-size: 10pt;font-family: Verdana;}',
  //     images_upload_url : 'upload.php',
  //     automatic_uploads : false,
  
  //     images_upload_handler : function(blobInfo, success, failure) {
  //       var xhr, formData;
  
  //       xhr = new XMLHttpRequest();
  //       xhr.withCredentials = false;
  //       xhr.open('POST', 'upload.php');
  
  //       xhr.onload = function() {
  //         var json;
  
  //         if (xhr.status != 200) {
  //           failure('HTTP Error: ' + xhr.status);
  //           return;
  //         }
  
  //         json = JSON.parse(xhr.responseText);
  
  //         if (!json || typeof json.file_path != 'string') {
  //           failure('Invalid JSON: ' + xhr.responseText);
  //           return;
  //         }
  
  //         success(json.file_path);
  //       };
  
  //       formData = new FormData();
  //       formData.append('file', blobInfo.blob(), blobInfo.filename());
  
  //       xhr.send(formData);
  //     },
  //   });
  // }
  createNewTicket(){
    var subject = $('#subject').val();
    var description = btoa(tinymce.activeEditor.getContent());
console.log(this.EmailToAddress)
    var email_cc = this.getFields(this.collobrators, "email_name"); 
var EmailTo = this.getFields(this.EmailToAddress, "email_to"); 

var formData = new FormData();
if((<HTMLInputElement>document.getElementById('create_file')).value != null){
  var ins = (<HTMLInputElement>document.getElementById('create_file')).files.length;
      for (var x = 0; x < ins; x++) {
        formData.append("up_files[]", (<HTMLInputElement>document.getElementById('create_file')).files[x]);
      }
    }
    formData.append('operation', 'ticket');
    formData.append('moduleType', 'ticket');
    formData.append('api_type', 'web');
    formData.append('action', 'composeInternalMail');
    formData.append('subject', subject);
    formData.append('description',description);
    formData.append('admin_id', this.admin_id);
    formData.append('user_id', this.user_id);
    formData.append('to', EmailTo);
    formData.append('from_address', this.email_from);
    formData.append('mail_cc', email_cc);  
 
    if (EmailTo == null || EmailTo == '') {
      this.showtoEmail = true;
      iziToast.warning({
        message: "Enter Email Address",
        position: 'topRight'
      });
      return false;
    } else {
      this.showtoEmail = false;
    }
    console.log(formData);
    if (description=='') {
      iziToast.warning({
        message: "Please Enter Email Text",
        position: 'topRight'
      });
      return false;
    }
    if (subject=='') {
      iziToast.warning({
        message: "Please Enter Email Subject",
        position: 'topRight'
      });
      return false;
    }
  //   var files = $('#up_files')[0].files;
  //   var totalSize = 0;
  //  for (var i = 0; i < files.length; i++) {
  //   totalSize += files[i].size;
  // }
  // if (totalSize < 5242880) {
  // }
  // else {
  //   iziToast.warning({
  //     message: "Sorry, File size should be below 5MB",
  //     position: 'topRight'
  //   });
  //   // alert("File size is more than 5MB");
  //   event.preventDefault();
  //   return false;
  // }
    if (EmailTo != '') {

      Swal.fire({
        title: 'Please Wait',
        allowEscapeKey: false,
        allowOutsideClick: false,
        //  background: '#19191a',
        showConfirmButton: false,
        onOpen: () => {
          Swal.showLoading();
        }
      });

      // return  false;

      $.ajax({
        url: "https://omnitickets.mconnectapps.com/api/v1.0/index_new.php",
        type: 'POST',
        data: formData,
        processData: false,  // tell jQuery not to process the data
        contentType: false,
        success: function (data) {
          this.parsed_data = JSON.parse(data);
          console.log(this.parsed_data);
          Swal.close();
          // if(this.parsed_data.result.status == "Message has been sent successfully"){   
          if (this.parsed_data.data == "Message has been sent successfully") {

        $("#refresh_profile").click();
        iziToast.success({
          message: "Ticket has been sent successfully",
          position: 'topRight'
      });
      // this.router.navigate(['/ticketing-system-new']);
    // $("#NavigateFunc").click();
      }
      else{
        iziToast.error({
          message: "Sorry, Some Error Occured,Please contact Admin",
          position: 'topRight'
      });    
      }
    }  
});  
    }

  }
  NavigateFunc(){
    this.router.navigate(['/internal-email']);
  } 
  getFields(input, field) {
    var output = [];
    for (var i=0; i < input.length ; ++i)
        output.push(input[i][field]);
    return output.toString();
  }

}
