import { Component, OnInit } from '@angular/core';
import { report } from 'process';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ServerService } from "../services/server.service";
import { Router } from "@angular/router";


@Component({
  selector: 'app-sales-support-report',
  templateUrl: './sales-support-report.component.html',
  styleUrls: ['./sales-support-report.component.css']
})
export class SalesSupportReportComponent implements OnInit {

  userId:any;
  adminId:any;
  accessToken:any; 
  reportListing:any;

  pageLimit = 20;
  paginationData:any ={"info":"hide"};
  offset_count = 0;
  recordNotFound = false;
  dept_data:any;
  clicktype:any;
  monthWiseList:any;
  ticketStatusType:any;
  selectedTicketStatus:any;
  fromdate:Date;
  todate:Date;
  selectedDept = new FormControl('');
  selectMonth ='';
  showclear = false;
  advanceDate = false;
  constructor(public serverService:ServerService , public router:Router) {
    this.userId = localStorage.getItem('userId');
    this.adminId = localStorage.getItem('admin_id');
    this.accessToken = localStorage.getItem('access_token');
   }

  ngOnInit(): void {
    
    this.listReportData({});
    this.monthWiseList =[
      {
      name:'7 Days',
      value:'1'
      },
      {
      name:'14 Days',
      value:'2'
      },
      {
      name:'Last One Month',
      value:'3'
      },
  ]

  }

  switchFilter(){
    
    this.advanceDate = ! this.advanceDate;
    if(this.advanceDate == true){

    }
  }

  ngAfterViewInit(){
    setTimeout(() => {
      this.dept_data = this.serverService.dept_list;
      this.ticketStatusType = this.serverService.emailStatusList;
    }, 2000);
  }


  customSearchFilter(enquiry_search){
    this.clicktype='searchbutton';
    this.showclear = true;
    this.listReportData({'search_text':enquiry_search});
  }

  adavanceCustomSearch(enquiry_search){
    this.clicktype = 'advancereport';
    this.showclear = true;
    this.listReportData({'search_text':enquiry_search});
  }

  

  clearSearch(){
      this.fromdate = null;
      this.todate = null;
      this.todate = null;
      this.selectedDept.setValue('');
      this.selectedTicketStatus = null;
      this.clicktype= null;
      this.showclear = false;
      this.selectMonth = '';
      this.listReportData({});
  }


  listReportData(data){

    var list_data= this.listDataInfo(data);
    let api_req:any = new Object();
    let report_req:any = new Object();

    console.log(this.clicktype);


    if(this.clicktype == 'searchbutton'){
      report_req.action="agent_deptwise_ticketreport_filter";  
      report_req.from_dt=this.fromdate;
      report_req.to_dt=this.todate;
      if(this.selectedDept.value != ''){
        report_req.dept_id= this.selectedDept.value.join();
      }
      report_req.status_id= this.selectedTicketStatus;

    }else if(this.clicktype == 'advancereport'){
      report_req.action="advancereport_filter";  
      if(this.selectedDept.value != ''){
      report_req.dept_id= this.selectedDept.value.join();
      }
      report_req.status_id= this.selectedTicketStatus;
      report_req.range= this.selectMonth;
    } else{
        report_req.action = "agent_deptwise_ticketreport";
    }


    api_req.operation = "ticket";
    api_req.moduleType = "ticket";
    api_req.api_type = "web";
    report_req.admin_id = this.adminId;
    report_req.user_id = this.userId;
    report_req.limit=list_data.limit;
    report_req.offset=list_data.offset;
    report_req.search_text=list_data.search_text;
    report_req.access_token = this.accessToken;
    api_req.element_data = report_req;


    this.serverService.sendServer(api_req).subscribe((response:any) =>{

      if(response.status  == 'true'){
        

          this.reportListing = response.data;
          this.offset_count = list_data.offset;
          this.paginationData = this.serverService.pagination({'offset':response.offset, 'total':response.total, 'page_limit' :this.pageLimit });
          if(this.reportListing != null)
          this.recordNotFound = this.reportListing.length == 0 ? true : false;
      
      } else {
        this.recordNotFound = true;
      }

    });


  
  }

  viewMyThread(ticket_id) {
		ticket_id = btoa(ticket_id);

		this.router.navigate(['/ticket-view-thread'], { queryParams: { ticket_id: ticket_id,page_type:'salessupportReport' } });

  }

  listDataInfo(list_data){

		list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
		list_data.order_by_name = list_data.order_by_name == undefined ? "history.callid" : list_data.order_by_name;
		list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
		list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
		list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
		return list_data;
  }

}
