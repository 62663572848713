import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
import { FormArray, FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';

declare var $: any;
declare var iziToast: any;
import Swal from 'sweetalert2'
@Component({
  selector: 'app-whitelist-mail',
  templateUrl: './whitelist-mail.component.html',
  styleUrls: ['./whitelist-mail.component.css']
})
export class WhitelistMailComponent implements OnInit {
  user_type;
  uadmin_id;
  user_id;
  id;
  addMails: FormGroup;
  editMails: FormGroup;
  admin_permission;
  admin_id;
  dep_status;
  listingemails;
  pageLimit = 50;
  paginationData: any = { "info": "hide" };
  offset_count = 0;
  updateEditValue;
  constructor(private serverService: ServerService, private router: Router,) { }

  ngOnInit() {

    this.uadmin_id = localStorage.getItem('admin_id');
    this.user_id = localStorage.getItem('admin_id');
    this.user_type = localStorage.getItem('user_type');
    this.admin_permission = localStorage.getItem('admin_permision');
    this.addMails = new FormGroup({
      'emailids': new FormControl(null, Validators.required)

    });
    this.editMails = new FormGroup({
      'emailids': new FormControl(null, Validators.required)

    });
    if (this.user_type == 'Super Admin') {
      this.user_type = 1;
    }
    else if (this.user_type == 'Admin' || this.admin_permission == '1') {
      this.user_type = 2;
    }
    else {
      this.user_type = 3;//This is page can see if agent have admin_permission
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'You have no access view that page!',
      });
      setTimeout(() => {
        this.router.navigate(['/']);
      }, 2000);
      return false;
    }

    this.whitelist({});

  }


  listDataInfo(list_data) {

    list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
    list_data.order_by_name = list_data.order_by_name == undefined ? "user.agent_name" : list_data.order_by_name;
    list_data.order_by_type = list_data.order_by_type == undefined ? "asc" : list_data.order_by_type;
    list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
    list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
    return list_data;
  }


  whitelist(data) {


    // {"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"", "element_data":{"action":"list_customer_whitelist","admin_id":"64","limit":"10","offset":"0"}}

    var list_data = this.listDataInfo(data);
    let api_req: any = new Object();
    let console_req: any = new Object();
    console_req.action = "list_customer_whitelist";
    console_req.admin_id = this.uadmin_id;
    console_req.search_text = list_data.search_text;
    console_req.limit = list_data.limit;
    console_req.offset = list_data.offset;
    api_req.operation = "ticket";
    api_req.moduleType = "ticket";
    api_req.api_type = "web";
    api_req.access_token = localStorage.getItem('access_token');
    api_req.element_data = console_req;

    this.serverService.sendServer(api_req).subscribe((response: any) => {

      if (response.status == "true") {
        this.listingemails = response.data;
        this.offset_count = response.list_info.offset;

        this.paginationData = this.serverService.pagination({ 'offset': response.list_info.offset, 'total': response.list_info.total, 'page_limit': this.pageLimit });

      }
    },
      (error) => {
        console.log(error);
      });


  }
  addMail_White() {
    $('#add_whitemailform').modal('show');
  }
  createWhiteMailList() {
    let agent_req: any = this.addMails.value;
    if (agent_req.status == true) { this.dep_status = 1 } else { this.dep_status = 0 }

    //let api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"blockEmailIds","admin_id":"' + this.uadmin_id + '","user_id":"' + user_id + '","email_id":"' + agent_values.emailids + '","spam_status":"' + spam_status + '","blacklist_status":"' + black_status + '"}}';
    let email: any = this.addMails.value.emailids;
    let api_req: any = new Object();
    let console_req: any = new Object();
    api_req.operation = "add_customer_whitelist";
    api_req.moduleType = "ticket";
    api_req.api_type = "web";
    api_req.access_token = localStorage.getItem('access_token');
    api_req.element_data = console_req;
    console_req.action = "add_customer_whitelist";
    console_req.admin_id = localStorage.getItem('admin_id');
    console_req.email = email;

    this.serverService.sendServer(api_req).subscribe((response: any) => {
      this.addMails.reset();
      if (response.result.data == 2) {
        iziToast.warning({
          message: "Email ID already inserted",
          position: 'topRight'
        }); 

      } else if 
        (response.result.status == 1) {
          var messages = '';
          messages = "Email Added Successfully";
          $('#add_whitemailform').modal('hide');
          iziToast.success({
            message: messages,
            position: 'topRight'
          });
          this.whitelist({});
      }
      else {

        iziToast.error({
          message: "Mail not added. Please try again",
          position: 'topRight'
        });

      }

    },
      (error) => {
        iziToast.error({
          message: "Email Updated Failed",
          position: 'topRight'
        });
        console.log(error);
      });

  }

  edit_whitelist_email(id) {

    let agent_req: any = this.addMails.value;
    if (agent_req.status == true) { this.dep_status = 1 } else { this.dep_status = 0 }
    
    let email: any = this.editMails.value.emailids;
    let api_req: any = new Object();
    let console_req: any = new Object();
    api_req.operation = "edit_customer_whitelist";
    api_req.moduleType = "ticket";
    api_req.api_type = "web";
    api_req.access_token = localStorage.getItem('access_token');
    api_req.element_data = console_req;
    console_req.action = "edit_customer_whitelist";
    console_req.id = id;

    console.log(api_req);

    this.serverService.sendServer(api_req).subscribe((response: any) => {

      if (response.result.data.status == 2) {
        iziToast.warning({
         message: "Email ID already inserted",
         position: 'topRight'
       }); 
       
     }else if(response.result.status == true) {
        var email_data = response.result.data;
        $('#edit_whitemailform').modal('show');
        this.editMails.setValue({
          'emailids': email_data.email
        });
        this.updateEditValue = id;
        // var messages = '';
        // messages = "Update Email ID";
        // $('#edit_whitemailform').modal('hide');
        // iziToast.success({
        //   message: messages,
        //   position: 'topRight'
        // });
        this.whitelist({});
       

      }
    
      else {
        iziToast.error({
          message: "Email Updation Failed",
          position: 'topRight'
        });

      }
    },
      (error) => {
        console.log(error);
      });
  }

  editWhiteMailList(id: any) {

    let api_req: any = new Object();
    let console_req: any = new Object();
    api_req.operation = "update_customer_whitelist";
    api_req.moduleType = "ticket";
    api_req.api_type = "web";
    api_req.access_token = localStorage.getItem('access_token');
    api_req.element_data = console_req;
    console_req.action = "update_customer_whitelist";
    console_req.id = this.updateEditValue;
    console_req.admin_id = localStorage.getItem('admin_id');
    console_req.email = this.editMails.value.emailids;
    console.log(api_req);
    // return false;
    this.serverService.sendServer(api_req).subscribe((response: any) => {
      if (response.result.data == 2) {
        iziToast.warning({
         message: "Email ID already exists",
         position: 'topRight'
       }); 
      }
      else if (response.result.data == 1) {
        var messages = '';
        messages = "Email Updated Successfully";
        $('#edit_whitemailform').modal('hide');
        iziToast.success({
          message: messages,
          position: 'topRight'
        });
        this.whitelist({});


      } else {
        iziToast.error({
          message: "Email Updation Failed",
          position: 'topRight'
        });

      }

    },
      (error) => {
        console.log(error);
      });


  }

  delete_whitelist_email(id: any) {

    Swal.fire({
      title: 'Are you sure?',
      text: "Would you like to delete this Email from Customer White list!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {

        let api_req: any = new Object();
        let console_req: any = new Object();
        api_req.operation = "delete_customer_whitelist";
        api_req.moduleType = "ticket";
        api_req.api_type = "web";
        api_req.access_token = localStorage.getItem('access_token');
        api_req.element_data = console_req;
        console_req.action = "delete_customer_whitelist";
        // console_req.id =this.updateEditValue;
        console_req.id = id;

        this.serverService.sendServer(api_req).subscribe((response: any) => {
          if (response.result.status == true) {
            iziToast.success({
              message: "Customer whitelist Email Delete Successfully",
              position: 'topRight'
            });
            this.whitelist({});

          } else {
            iziToast.error({
              message: "Failed to Delete",
              position: 'topRight'
            });
          }
        },
          (error) => {
            console.log(error);
          });

      }

    })
  }
}



