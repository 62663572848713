import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router  } from '@angular/router';
import { ServerService } from '../services/server.service';

import { DomSanitizer } from '@angular/platform-browser';
import {Location} from '@angular/common';
// import { Router } from '@angular/router';
// import { Console } from 'console';
declare var $ : any;
@Component({
  selector: 'app-kb-list-view',
  templateUrl: './kb-list-view.component.html',
  styleUrls: ['./kb-list-view.component.css']
})
export class KbListViewComponent implements OnInit {
filename:any;
id:any;
category:any;
params:any;
url:any;
sub_category:any;
sub_category_name:any;
filesource:any;
post_content:any;
post_id:any;
post_title:any;
featured_list:any;
profile_image;
recordnotFound_featured=false
static get parameters() {
  return [DomSanitizer];
}
constructor(private activatedRoute: ActivatedRoute,private service :ServerService,private sanitizer: DomSanitizer,private Location:Location,private router:Router ) {
   
  }
ngOnInit(): void {
//   this.activatedRoute.params.subscribe(params => {
//     this.post_title =  params['data'].replace(/-/g, ' ');   
//     console.log('params',params);
//  }); 
if (this.activatedRoute.params) {
  this.activatedRoute.params.subscribe(params => {
    if (params && params['data']) {
      this.post_title = params['data'].replace(/-/g, ' ');   
      console.log('params', params);
      this.display1();
    }
  });
}
// this.service.kb_url.subscribe((kb_list:any)=>{
//   console.log(kb_list)
//  }) 
     this.display1();
    

    }
    display1() {
      console.log('Before sendServer call', this.service);
      let api_req: any = '{"operation": "category","moduleType": "category","api_type": "web","element_data": {"action":"displaying","post_title":"'+this.post_title+'"}}';
      this.service.sendServer(api_req).subscribe((response: any) => {
        // ... rest of the code
      }, (error) => {
        console.log('Error in sendServer', error);
      });
    }
    // display(){
      
    //   let api_req:any = '{"operation": "category","moduleType": "category","api_type": "web","element_data": {"action":"displaying","post_title":"'+this.post_title+'"}}';
    // this.service.sendServer(api_req).subscribe((response: any)=>{
    //   // console.log(api_req);
    //   if(response.result.status==true){
    //     this.category = response.result.data;
    //     this.filename=response.result.data[0].filename;
    //     var ext = response.result.data[0].filename.substr(response.result.data[0].filename.lastIndexOf('.') + 1);
    //    if(ext=='doc' || ext=='docx'){
    //     let file='https://view.officeapps.live.com/op/view.aspx?src='+response.result.data[0].filename+'&wdOrigin=BROWSELINK';
    //      this.filesource=this.sanitizer.bypassSecurityTrustResourceUrl(file);
    //    }
    //    else{
    //     this.filesource=this.sanitizer.bypassSecurityTrustResourceUrl(response.result.data[0].filename);
    
    //     console.log(this.filesource)
    //     this.post_content=atob(response.result.data[0].post_content)
    //     console.log(this.category);
    //   // // console.log(response.result.data[0].sub_category);
    //   //   // this.router.navigate(['display']);
    
    //   } 
    //   //else {
    //   //   alert('123')
    //   }
    
    // },
    // (error)=>{
    //     console.log(error);
    // });
    // }

RouterMain(id:any){
     
  this.router.navigate(['/private'], { queryParams: { selectedTab:id} });
}
 
ViewFeatArticle(data:any){   
  // this.router.navigateByUrl('/view-articles/id');
  
  this.router.navigate(['private-kb/kb-list-view',data]);
  setTimeout(() => {
  window.location.reload();
    
  }, 1000);
}


GoBack(){
  this.Location.back();
}
}

