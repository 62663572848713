import { Component, OnInit } from '@angular/core';
import { ServerService } from "./../services/server.service";
import { Router } from "@angular/router";
import { FormArray, FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

declare var tinymce:any;
declare var iziToast:any;
@Component({
  selector: 'app-mrvoip-api-kb',
  templateUrl: './mrvoip-api-kb.component.html',
  styleUrls: ['./mrvoip-api-kb.component.css']
})
export class MrvoipApiKbComponent implements OnInit {
  categorylists:any;
  subcategorylists:any;
  catselect : any;
  subcatselect : any;
  
  mrvoipApiForms: FormGroup;
  
  public addresses: any[] = [{
    id: 1,
    parameter: '',
    datatype: '',
    description: ''
  }];
  public errors: any[] = [{
    id: 1,
    status: '',
    message: '',
    reason: ''
  }];
  
  code_lang : any;

  constructor(public serverService: ServerService, public router:Router, private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    this.mrvoipApiForms= new FormGroup({
      'post_title' :new FormControl(null),
      'status_check' :new FormControl(null),
      'private' :new FormControl(null),
      'request_url' :new FormControl(null),
      'catselect_ids' :new FormControl(null),
      'subcat_ids' :new FormControl(null),
    });

    this.initTiny2();
    this.initTiny();
    this.getcategory();
    this.getsubcategory();
    


  }

  addAddress() {
    this.addresses.push({
      id: this.addresses.length + 1,
      parameter: '',
      datatype: '',
      description: ''
    });
  }
  addAddress2() {
    this.errors.push({
      id: this.errors.length + 1,
      status: '',
      message: '',
      reason: ''
    });
  }

  removeAddress(i: number) {
    this.addresses.splice(i, 1);
  }
  removeAddress2(i: number) {
    this.errors.splice(i, 1);
  }

  logValue() {
    console.log(this.addresses);
  }




  initTiny2() {
    var richTextArea_id = 'sampleResponse';
   tinymce.init({
     selector: '#sampleResponse',
     height: 350,
     plugins: 'advlist autolink formatpainter lists link  image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste  wordcount autolink lists media table',
     toolbar: 'undo redo |fullscreen|forecolor backcolor| formatselect | bold italic | \ undo redo | link image file| code | \
       alignleft aligncenter alignright alignjustify | \
       bullist numlist outdent indent | autoresize',
     paste_data_images: true,
     images_upload_url: 'upload.php',
     automatic_uploads: false,
     default_link_target:"_blank",
     extended_valid_elements : "a[href|target=_blank]",
     link_assume_external_targets: true,
    //  link_context_toolbar: true,
     images_upload_handler: function (blobInfo, success, failure) {
       var xhr, formData;

       xhr = new XMLHttpRequest();
       xhr.withCredentials = false;
       xhr.open('POST', 'upload.php');

       xhr.onload = function () {
         var json;

         if (xhr.status != 200) {
           failure('HTTP Error: ' + xhr.status);
           return;
         }

         json = JSON.parse(xhr.responseText);

         if (!json || typeof json.file_path != 'string') {
           failure('Invalid JSON: ' + xhr.responseText);
           return;
         }

         success(json.file_path);
       };

       formData = new FormData();
       formData.append('file', blobInfo.blob(), blobInfo.filename());

       xhr.send(formData);
     },
   });
   if (tinymce.editors.length > 0) {
     //tinymce.execCommand('mceFocus', true, richTextArea_id );       
     tinymce.execCommand('mceRemoveEditor',true, richTextArea_id);        
     tinymce.execCommand('mceAddEditor',true, richTextArea_id);
 }
 }
  initTiny() {
    var richTextArea_id = 'newrichTextAreaReply';
   tinymce.init({
     selector: '#newrichTextAreaReply',
     height: 500,
     plugins: 'advlist autolink formatpainter lists link  image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste  wordcount autolink lists media table',
     toolbar: 'undo redo |fullscreen|forecolor backcolor| formatselect | bold italic | \ undo redo | link image file| code | \
       alignleft aligncenter alignright alignjustify | \
       bullist numlist outdent indent | autoresize',
     paste_data_images: true,
     images_upload_url: 'upload.php',
     automatic_uploads: false,
     default_link_target:"_blank",
     extended_valid_elements : "a[href|target=_blank]",
     link_assume_external_targets: true,
    //  link_context_toolbar: true,
     images_upload_handler: function (blobInfo, success, failure) {
       var xhr, formData;

       xhr = new XMLHttpRequest();
       xhr.withCredentials = false;
       xhr.open('POST', 'upload.php');

       xhr.onload = function () {
         var json;

         if (xhr.status != 200) {
           failure('HTTP Error: ' + xhr.status);
           return;
         }

         json = JSON.parse(xhr.responseText);

         if (!json || typeof json.file_path != 'string') {
           failure('Invalid JSON: ' + xhr.responseText);
           return;
         }

         success(json.file_path);
       };

       formData = new FormData();
       formData.append('file', blobInfo.blob(), blobInfo.filename());

       xhr.send(formData);
     },
   });
   if (tinymce.editors.length > 0) {
     //tinymce.execCommand('mceFocus', true, richTextArea_id );       
     tinymce.execCommand('mceRemoveEditor',true, richTextArea_id);        
     tinymce.execCommand('mceAddEditor',true, richTextArea_id);
 }
 }


  getcategory(){
    let api_req:any = '{"operation": "category","moduleType": "category","api_type": "web","element_data": {"action":"selectcategory"}}';
    this.serverService.sendServer(api_req).subscribe((response: any)=>{
      console.log(response)
      if(response.status == true){
        // console.log("asdf")
        this.categorylists = response.result.data;
      }
  },(error: any)=>{
      console.log(error)
  });

}
getsubcategory(){
  let api_req:any = '{"operation": "category","moduleType": "category","api_type": "web","element_data": {"action":"select_sub_category","category_id":"'+this.catselect+'"}}';
  this.serverService.sendServer(api_req).subscribe((response: any)=>{
    console.log(response)
    if(response.status == true){
      // console.log("asdf")
      this.subcategorylists = response.result.data;
    }
},(error: any)=>{
    console.log(error)
});

}




postupload(){

  console.log(this.addresses)
  console.log(this.errors)
  var parameters1 = this.addresses.map(function(val) {
    return val.parameter +'||' +val.datatype +'||' +val.description;
  }).join(',');
  var errors2 = this.errors.map(function(val) {

    return val.status +'||' +val.message +'||' +val.reason;
  }).join(',');

  // can be convert to json using this code

//   var jsonfied = {
//     names: names.replace( /,$/, "" ).split(",").map(function(name) {
//         return {name: name};
//     })
// };

let sample_response = tinymce.get('sampleResponse').getContent();


  let api_req:any = new Object();
  let postuploading:any = new Object();
  api_req.operation = 'category';
  api_req.moduleType = 'category';
  api_req.api_type = 'web';
  postuploading.action = 'post_mrvoipapi_data';
  postuploading.title = this.mrvoipApiForms.value.post_title;
  postuploading.category_id = this.mrvoipApiForms.value.catselect_ids;
  postuploading.subcategory_id = this.mrvoipApiForms.value.subcat_ids;
  postuploading.hide_status = this.mrvoipApiForms.value.status_check;
  postuploading.url = this.mrvoipApiForms.value.request_url;
  postuploading.data_type = this.mrvoipApiForms.value.private;
  postuploading.parameters = parameters1;
  postuploading.errors = errors2;
  postuploading.sample_response = btoa(sample_response);
  api_req.element_data = postuploading;

  
  this.serverService.sendServer(api_req).subscribe((response: any)=>{
    console.log(response)
    if(response.result.status == true){
     
      iziToast.success({
        message: "Datas Saved Successfully",
        position: 'topRight'
      });
    
    }else if (response.result.data == '1'){
    
      iziToast.error({
        message: "Record already Found !",
        position: 'topRight'
      });

    }else{

      iziToast.error({
        message: "Datas saved Failed!",
        position: 'topRight'
      });

    }
},(error: any)=>{
    console.log(error)
});


}

sendCodeLanguage(){

  let smaple_req = tinymce.get('newrichTextAreaReply').getContent();

  console.log(smaple_req);
  console.log(this.code_lang);

  
  let api_req:any = new Object();
  let postuploading:any = new Object();
  api_req.operation = 'category';
  api_req.moduleType = 'category';
  api_req.api_type = 'web';
  postuploading.action = 'code_lang_add';
  postuploading.title = this.code_lang;
  postuploading.code_content = btoa(smaple_req);

  api_req.element_data = postuploading;

 this.serverService.sendServer(api_req).subscribe((response: any)=>{
    console.log(response)
    if(response.status == true){
     
      iziToast.success({
        message: "Datas Saved Successfully",
        position: 'topRight'
      });
    
    }else{

      iziToast.error({
        message: "Datas saved Failed!",
        position: 'topRight'
      });

    }
},(error: any)=>{
    console.log(error)
});


}


}
